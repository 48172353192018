<template>
  <v-card flat class="rounded-lg pa-2" height="100%">
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <create-service-form
        ref="serviceForm"
        :key="componentKey"
        :form="form.service"
        :itemId="itemId"
      ></create-service-form>
      <component-selector></component-selector>
    </v-card-text>
    <v-card-actions class="px-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        class="bg-secondary text-color-white"
        @click="submit()"
        :loading="loading"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
import { BUILDER_COMPONENT_EVENT_BUS } from "../services/builderComponentEventBus";
// import moment from "moment";

const experienceRepository = RepositoryFactory.get("experience");

const ComponentSelector = () => import("../builder/ComponentSelector.vue");
const CreateServiceForm = () => import("../forms/CreateServiceForm.vue");

import { convertUtcToLocal, setToUtc } from "@/services/helpers/date";

export default {
  components: {
    ComponentSelector,
    CreateServiceForm
  },
  data: () => ({
    title: "Add Scheduled Service",
    componentKey: 0,
    loading: false,
    itemId: null,
    form: {},
    builderContent: [],
    experienceId: null
  }),
  methods: {
    update(form) {
      experienceRepository
        .update(this.itemId, form)
        .then(async response => {
          this.experienceId = response.data.responseData.id;
          // GLOBAL_EVENT_BUS.$emit("reloadItems_services");
          await this.getBuilderData();

          await this.MIXINS_SHOW_SNACKBAR("success", "Successfully Updated.");

          await this.MIXINS_GO_TO_PAGE("/experiences");
        })
        .catch(error => {
          console.log({ error });
          let objectError = error.response.data.errors;

          for (const [value] of Object.entries(objectError)) {
            this.MIXINS_SHOW_SNACKBAR("error", value[0]);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(form) {
      console.log(form);
      experienceRepository
        .create(form)
        .then(async response => {
          this.experienceId = response.data.responseData.id;
          // GLOBAL_EVENT_BUS.$emit("reloadItems_services");
          await this.getBuilderData();

          await this.MIXINS_SHOW_SNACKBAR("success", "Successfully Created.");

          await this.MIXINS_GO_TO_PAGE("/experiences");
        })
        .catch(error => {
          console.log({ error });
          let objectError = error.response.data.errors;

          for (const [value] of Object.entries(objectError)) {
            this.MIXINS_SHOW_SNACKBAR("error", value[0]);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submit() {
      let serviceForm = this.$refs.serviceForm;

      let valid = serviceForm.validate();

      console.log(serviceForm.form);

      if (valid) {
        this.loading = true;

        let form = { ...serviceForm.form };
        // form.start_at = `${form.start_date} ${form.start_time}:00`;
        // form.end_at = `${form.end_date} ${form.end_time}:00`;
        const startAtDatetimeFormat = `${form.start_date} ${form.start_time}:00`;
        const endAtDatetimeFormat = `${form.end_date} ${form.end_time}:00`;

        form.start_at = setToUtc(startAtDatetimeFormat).format("YYYY-MM-DD HH:mm:ss")
        form.end_at = setToUtc(endAtDatetimeFormat).format("YYYY-MM-DD HH:mm:ss")

        // form.start_at = moment(startAtDatetimeFormat).utc().format("YYYY-MM-DD HH:mm:ss")
        // form.end_at = moment(endAtDatetimeFormat).utc().format("YYYY-MM-DD HH:mm:ss")

        if (this.itemId) {
          await this.update(form);
        } else {
          await this.create(form);
        }
      }
    },
    getBuilderData() {
      BUILDER_COMPONENT_EVENT_BUS.$emit("getBuilderData");
    },
    setBuilderDataEventBus() {
      BUILDER_COMPONENT_EVENT_BUS.$off("setBuilderData");
      BUILDER_COMPONENT_EVENT_BUS.$on("setBuilderData", data => {
        if (data.length >= 1) {
          for (let item of data) {
            let partId = item.id;

            if (partId) {
              experienceRepository
                .updatePart(this.experienceId, partId, item)
                .then(response => {
                  console.log({ response }, "BUILDER");
                })
                .catch(error => {
                  console.log({ error });
                });
            } else {
              experienceRepository
                .createPart(this.experienceId, item)
                .then(response => {
                  console.log({ response }, "BUILDER");
                })
                .catch(error => {
                  console.log({ error });
                });
            }
          }
        }
      });
    },
    setupEventBus() {
      this.setBuilderDataEventBus();
    },
    setData() {
      let id = this.$route.params.id;

      if (id) {
        this.itemId = parseInt(id);

        this.title = "Update Scheduled Service";

        experienceRepository
          .show(id)
          .then(response => {
            let data = response.data.responseData;
            const startAt = convertUtcToLocal(data.start_at).format("YYYY-MM-DD HH:mm:ss");
            const endAt = convertUtcToLocal(data.end_at).format("YYYY-MM-DD HH:mm:ss");
            // const startAt = moment(moment.utc(data.start_at).toDate()).format("YYYY-MM-DD HH:mm:ss");
            // const endAt = moment(moment.utc(data.end_at).toDate()).format("YYYY-MM-DD HH:mm:ss");
            this.form = {
              service: {
                title: data.title,
                pastor_name: data.pastor_name,
                location_id: data.location_id,
                start_date: startAt
                  ? startAt.substring(0, 10)
                  : null,
                end_date: endAt ? endAt.substring(0, 10) : null,
                start_time: startAt
                  ? startAt.substring(11, 16)
                  : null,
                end_time: endAt ? endAt.substring(11, 16) : null
              }
            };
          })
          .catch(error => {
            console.log({ error });
          });

        return;
      }
    }
  },
  mounted() {
    this.setupEventBus();
    this.setData();
  }
};
</script>
